import React from "react";
import { Link } from "react-router-dom";

const About = () => {

  return (
    <div>
      <div className="page-wraper">
        <div className="page-content">
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5">
              <div className="dez-bnr-inr-entry text-start">
                <h1 className="text-white">About us</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5">
              <ul className="list-inline text-start">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About us</li>
              </ul>
            </div>
          </div>
          <div className="clearfix">
            <div
              className="section-full bg-gray content-inner"
              style={{
                backgroundImage: "url(images/bg-img.png)",
                backgroundRepeat: "repeat-x",
                backgroundPosition: "left bottom -37px",
              }}
            >
              <div className="container px-5">
                <div className="section-content">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 text-start">
                      <h1 className="m-b20"> About Company</h1>
                      <div
                        className="dez-separator"
                        style={{ background: "#9a2331" }}
                      />
                      <div className="clear" />
                      <p>
                      <strong>Welcome to Aranath Group</strong>, a leading turnkey construction firm established in 2016. With a proud legacy spanning over years, we have successfully executed projects totaling 1.5 million square feet, encompassing diverse sectors such as residential (including low-rise, high-rise, bungalows, and farmhouses), commercial, and institutional buildings. <br/>
Our dedicated team of experienced construction and management professionals ensures every project meets the highest standards of excellence. Safety and sustainability are paramount in our approach, as we strive to create enduring structures that harmonize with the environment and serve the needs of generations to come.</p>
                      <div className="row d-flex justify-content-center mt-5">
                        <div className="col-md-6 col-lg-6">
                          <div className="icon-bx-wraper left m-b30">
                            <div className="icon-bx-sm bg-light">
                              {" "}
                              <span className="icon-cell">
                                <i
                                  className="fa fa-building"
                                  style={{ color: "#9a2331" }}
                                />
                              </span>{" "}
                            </div>
                            <div className="icon-content">
                              <h3 className="fs-4 text-uppercase">
                                Real Estate Development{" "}
                              </h3>
                              <p>
                                We provide the best construction project for
                                you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="icon-bx-wraper left m-b30">
                            <div className="icon-bx-sm bg-light">
                              {" "}
                              <span className="icon-cell">
                                <i
                                  className="fa fa-solid fa-screwdriver-wrench"
                                  style={{ color: "#9a2331" }}
                                />
                              </span>{" "}
                            </div>
                            <div className="icon-content">
                              <h3 className="fs-4 text-uppercase">
                                Turnkey Construction
                              </h3>
                              <p>
                                Our architect service provides high-end design
                                for you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="icon-bx-wraper left m-b30">
                            <div className="icon-bx-sm bg-light ">
                              {" "}
                              <span className="icon-cell">
                                <i
                                  className="fa-solid fa-hand-holding-dollar"
                                  style={{ color: "#9a2331" }}
                                />
                              </span>{" "}
                            </div>
                            <div className="icon-content">
                              <h3 className="fs-4 text-uppercase">
                                Investments &amp; Consulting
                              </h3>
                              <p>
                                Our consulting team is always ready to help you.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="dez-thu m-b30">
                        <img src="images/aboutimg.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
