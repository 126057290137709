import React, { useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [active, setActive] = useState(true);
  const project = [
    {
      title: "Sattva Exotica",
      image: "pic4.jpg",
    },
    {
      title: "Shubh Greens",
      image: "shubh.jpeg",
    },
    {
      title: "Tata Projects – Anant University",
      image: "pic3.jpg",
    },
    {
      title: "Sanskardham",
      image: "pic4.jpg",
    },
    {
      title: "Ozone Tuscany",
      image: "pic5.jpg",
    },
    {
      title: "Iscon LIG Housing",
      image: "pic6.jpg",
    },
    {
      title: "SPG Evans",
      image: "pic8.jpg",
    },
    {
      title: "Sepal Elegant",
      image: "pic9.jpg",
    },
    {
      title: "Sattva Exotica",
      image: "pic2.jpg",
    },
    {
      title: "Hirabaug",
      image: "pic3.jpg",
    },
    {
      title: "Private Bungalow at Khoraj",
      image: "pic4.jpg",
    },
  ];

  const toggle = () => {
    setActive(!active);
  };
  return (
    <div className="page-wraper">
      <div className="page-content">
        <div className="landingpage">
          <ul>
            <li>
              <p>
                Welcome to Aranath Group - your turnkey construction partner.
                With expertise from concept to completion, we deliver
                excellence, quality, and sustainability, ensuring client
                satisfaction. Let us bring your construction vision to life
                efficiently and effectively.
              </p>
            </li>
          </ul>
        </div>
        {/* meet & ask */}
        <div className="section-full z-index100 meet-ask-outer bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 meet-ask-row p-tb30">
                <div className="row d-flex">
                  <div className="col-lg-6">
                    <div className="icon-bx-wraper clearfix left">
                      <div className="icon-xl ">
                        {" "}
                        <span className=" icon-cell text-white">
                          <i className="flaticon-buildings" />
                        </span>{" "}
                      </div>
                      <div className="icon-content text-white">
                       
                        <h3 className="dez-tilte text-uppercase m-b10">
                          Meet &amp; Ask
                        </h3>
                        <p>
                          Constructing a modern website requires modern tools.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 m-t20">
                  <Link to="/contact" >
                    <a
                      href="#"
                      className="site-button-secondry button-skew m-l10"
                    >
                      <span>Contact Us</span>
                      <i className="fa fa-angle-right" />
                    </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-full content-inner overlay-secondary-dark text-white bg-img-fix"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(images/background/bg18.jpg)",
          }}
        >
          <div className="container px-5">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2">
                    <i className="fa fa-building-shield" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">12</b>
                      <span>Projects</span>
                    </div>
                    <span className="font-16 font-monospace">DELIEVERED</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2">
                    <i className="fa fa-person-digging" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">1.5</b>
                      <span>Mn Sq Ft</span>
                    </div>
                    <span className="font-16 font-monospace">CONSTRUCTED</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2 pb-4">
                    <i className="fa fa-person-falling" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">0</b>
                    </div>
                    <span className="font-16 word-wrap font-monospace">
                      INCIDENT SITES
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center bg-light h-100 w-100">
          <div className="container-fluid my-5">
            <div className="d-flex justify-content-center">
              <ul className="">
                <li
                  onClick={toggle}
                  className={`projectbtn btn border-0 px-0 ${
                    active === true ? "active" : ""
                  }`}
                >
                  <a className="site-button-secondry button-skew">
                    <span className="">Ongoing</span>
                  </a>
                </li>
                <li
                  onClick={toggle}
                  className={`projectbtn btn border-0 px-0 ${
                    active === false ? "active" : ""
                  }`}
                >
                  <a className="site-button-secondry button-skew">
                    <span className="">Completed</span>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="clearfix clearfixProjects"
              style={{ height: "100% !important" }}
            >
              <ul className="h-100 row d-flex justify-content-start align-items-center">
                {
                  active === true ? (
                    <li className="completed projectimg col-lg-3 col-md-3 col-sm-12 col-12">
                      <div className="dez-box debox">
                        <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                          <img src={`images/gallery/tatva.jpg`} alt="" />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <h2 className="m-t0 text-white">Tatva 3</h2>
                              <div className="clearfix m-b15">
                                <div className="dez-separator bg-white"></div>
                              </div>
                              <a
                                href={`images/gallery/tatva.jpg`}
                                className=""
                                target="_blank"
                                title="View image"
                              >
                                <i className="projecticon fa fa-image d-flex justify-content-center align-items-center icon-bx-xs radius-sm"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    project?.map((value, index) => {
                      return (
                        <li
                          className="completed projectimg col-lg-3 col-md-3 col-sm-12 col-12 mb-4"
                          key={index}
                        >
                          <div className="dez-box debox">
                            <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                              <img
                                src={`images/gallery/${value?.image}`}
                                alt=""
                              />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <h2 className="m-t0 text-white">
                                    {value?.title}
                                  </h2>
                                  <div className="clearfix m-b15">
                                    <div className="dez-separator bg-white"></div>
                                  </div>
                                  <a
                                    href={`images/gallery/${value?.image}`}
                                    className=""
                                    target="_blank"
                                    title="View image"
                                  >
                                    <i className="projecticon fa fa-image d-flex justify-content-center align-items-center icon-bx-xs radius-sm"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  )}
              </ul>
            </div>
          </div>
        </div>

        <div className="section-full content-inner ">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="text-uppercase">Latest blog post</h2>
              <div className="dez-separator-outer ">
                <div className="dez-separator bg-secondry style-skew" />
              </div>
            </div>
            <div className="section-content ">
              <div className="blog-carousel owl-carousel owl-none">
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <a href="/leveraging-bim">
                        <img src="images/blog/latest-blog/pic1.jpg" alt="" />
                      </a>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <a href="/leveraging-bim">
                            Leveraging BIM: Revolutionizing the Construction
                            Industry
                          </a>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <a href="javascript:void(0);">demongo</a>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <a href="javascript:void(0);">0 comment</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                          In recent years, the construction industry has
                          undergone a significant transformation with the advent
                          of Building Information Modeling (BIM). BIM is not
                          just another tool; it's a game-changer,
                          revolutionizing the way we design, construct, and
                          manage buildings. Let's delve into what BIM is, its
                          myriad benefits, and how it saves both time and costs
                          in construction projects
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <a href="/embracing-sustainability">
                        <img src="images/blog/latest-blog/pic2.jpg" alt="" />
                      </a>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <a href="/embracing-sustainability">
                            Embracing Sustainability: A Guide to Eco-Friendly
                            Construction Practices
                          </a>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <a href="javascript:void(0);">demongo</a>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <a href="javascript:void(0);">0 comment</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                          In the face of escalating climate change concerns, the
                          construction industry is undergoing a pivotal shift
                          towards sustainable practices. As global awareness of
                          environmental issues continues to grow, it's
                          imperative for contractors and developers to
                          prioritize sustainability in their projects. Let's
                          explore why sustainable construction matters in the
                          fight against climate change and what steps can be
                          taken to embrace eco-friendly practices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <a href="/the-turnkey-advantage">
                        <img src="images/blog/latest-blog/pic3.jpg" alt="" />
                      </a>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <a href="/the-turnkey-advantage">
                            The Turnkey Advantage: Streamlining Construction
                            Projects for Efficiency and Success
                          </a>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <a href="javascript:void(0);">demongo</a>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <a href="javascript:void(0);">0 comment</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                          In the fast-paced world of construction, time, energy,
                          and money are precious commodities. For real estate
                          developers seeking to streamline their projects and
                          maximize efficiency, turnkey contracts with material
                          procurement offer a game-changing solution. Let's
                          explore how opting for turnkey contracts over
                          individual labor contracts can save time, energy, and
                          money while allowing developers to focus on what
                          matters most.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="scroltop fa fa-arrow-up style5"
          style={{ background: "#9a2331", color: "#fff" }}
        />
      </div>
    </div>
  );
};

export default Home;
