import "./App.css";
import MainRoutes from "./route/MainRoutes";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer
      />
      <MainRoutes />
    </div>
  );
}

export default App;
